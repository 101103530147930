<template>
  <div class="setupAccount">
    <el-drawer
      title="谷歌账户回传结果"
      size="1200px"
      :visible="returnResultShow"
      @close="toggleVisible(false)"
      append-to-body
      @opened="openInit"
      class=""
    >
      <div class="p-4">
        <div class="flex align-middle justify-between py-2">
          <el-button
            type="primary"
            size="small"
            @click="returnBack"
            >重新回传</el-button
          >
          <el-date-picker
            size="small"
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="setTime"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          v-loading="loading"
        >
          <el-table-column
            prop="conversionDate"
            label="日期"
          >
          </el-table-column>
          <el-table-column
            prop="utmSource"
            label="订单来源"
          >
          </el-table-column>
          <el-table-column
            prop="totalCount"
            label="订单数量"
          >
          </el-table-column>
          <el-table-column
            prop="totalCount"
            label="回传数量"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              回传数量
              <el-tooltip
                class="item"
                effect="dark"
                placement="top-start"
              >
                <div slot="content">
                  一般回传后在3个小时内，可在广告账户查看到转化数<br />ios订单最高延迟5天，其他订单最高延迟3天<br />如广告账户转化数一直没有变动，请在点击重新回传后，等3个小时左右再次查看
                </div>
                <el-icon class="el-icon-warning"></el-icon>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <div>
                <p>IOS:{{ scope.row.iosCount }}</p>
                <p>其他:{{ scope.row.otherCount }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="回传状态">
            <template slot-scope="scope">
              <div>
                <p>成功:{{ scope.row.successCount }}</p>
                <p>
                  失败:{{ scope.row.failCount }}
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top-start"
                    v-if="scope.row.failCount"
                  >
                    <div slot="content">
                      请检查以下3项：<br />1、确认广告账户对应的凭证有效<br />2、确认回传对应的是账户层级<br />3、确认广告账户有配置转化事件<br />4、确认订单是否有效<br />确认都满足条件之后，点击重新回传稍作等待即可
                    </div>
                    <el-icon class="el-icon-warning"></el-icon>
                  </el-tooltip>
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <resend-back
      :reSendBackShow.sync="reSendBackShow"
      :utmSource="utmSourceConfig"
      :accountId="accountId"
    ></resend-back>
  </div>
</template>

<script>
import { returnResult } from '@/api/google/account';
import resendBack from './resendBack.vue';
import moment from 'moment';
export default {
  props: {
    returnResultShow: Boolean,
    account: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    resendBack,
  },
  computed: {
    utmSourceConfig() {
      return this.account?.utmSourceConfig || '';
    },
    accountId() {
      return this.account?.id || '';
    },
  },
  data() {
    return {
      loading: false,
      tableData: [],
      time: [],
      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          const ninetyDaysAgo = new Date();
          ninetyDaysAgo.setDate(today.getDate() - 90);
          // 禁用今天之后的日期和90天前的日期
          return time.getTime() > today.getTime() || time.getTime() < ninetyDaysAgo.getTime();
        },
      },
      reSendBackShow: false,
    };
  },
  methods: {
    toggleVisible() {
      this.$emit('update:returnResultShow', false);
    },
    openInit() {
      this.time = [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
      this.getList({
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      });
    },
    getList(time) {
      let data = {
        accountId: this.account.id,
        utmSource: this.account.utmSourceConfig,
        ...time,
      };
      this.loading = true;
      returnResult(data).then((res) => {
        this.tableData = res.data;
        this.loading = false;
      });
    },
    setTime(v) {
      if (v && v.length > 0) {
        this.getList({ startDate: v[0], endDate: v[1] });
      }
    },
    // 点击重新回传
    returnBack(v) {
      this.reSendBackShow = true;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.setupAccount {
  ::v-deep .el-dialog {
    border-radius: 10px;
  }
  .tips {
    font-size: 12px;
    color: #999;
    height: 20px;
    line-height: 20px;
  }
}
</style>
